<template>
  <div class="print-page position-relative text-black">

    <div class="subpage">
      <slot />
    </div>

    <div class="header position-absolute position-top-0 position-left-0 w-100 py-1 pl-2 ml-75 pr-3">
      <h3 class="float-left">
        <logo-icon-for-print />
        <div class="d-inline-block position-absolute pt-50 pl-50 text-uppercase">
          {{ title }}
        </div>
      </h3>
    </div>

    <svg
      v-if="barcodeText"
      id="barcode"
      class="barcode position-absolute position-bottom-0 position-right-0 mb-5"
    />

    <div class="footer position-absolute position-bottom-0 position-left-0 w-100 pt-1 pb-75 px-3">
      <div class="float-left">
        <small class="italic">
          Wydruk dnia {{ now }}, przez {{ me.surname }} {{ me.name }},
          za pomocą systemu eAmbulans
        </small>
      </div>
      <div class="float-right text-right">
        {{ $t('print.page' ) }}: {{ pageNumber }}
        <span> / {{ pageTotal }}</span>
      </div>
    </div>

  </div>
</template>
<script>
import { cloneNested, transformDatetimeToHumanReadablePretty } from '@/helpers/helpers'
import moment from 'moment'
import JsBarcode from 'jsbarcode'
import LogoIconForPrint from '@core-override/layouts/components/LogoIconForPrint.vue'

export default {
  components: { LogoIconForPrint },
  props: {
    title: {
      type: String,
      default: null,
    },
    pageNumber: {
      type: Number,
      default: null,
    },
    pageTotal: {
      type: Number,
      default: null,
    },
    barcodeText: {
      type: String,
      default: null,
    },
  },
  computed: {
    now() {
      return transformDatetimeToHumanReadablePretty(moment())
    },
    me() {
      return cloneNested(this.$store.getters['auth/getUser'])
    },
  },
  mounted() {
    JsBarcode('#barcode', this.barcodeText, {
      width: 1.5,
      height: 15,
      displayValue: false,
    })
  },
}
</script>

<style lang="scss">
.vs-divider-border, .border-gray {
  border-color: gray !important;
}

.print-page {
  width: 210mm;
  height: 293mm;
  padding: 17mm 10mm 10mm;
  margin: 1rem auto 1rem;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);

  .subpage {
    padding: 0cm;
    height: 264mm;
    //outline: 1cm #FFEAEA solid;
  }
}

.barcode {
  -ms-transform: rotate(90deg) !important;
  transform: rotate(90deg) !important;
  transform-origin: top right;
}

.custom-print-border-right {
  &:after {
    content: '';
    display: block;    border-right: 1px solid #999;
    position: absolute;
    width: 0;
    top: 3rem;
    right: 0;
    bottom: 0;
  }

  &.from-top {
    &:after {
      top: 0;
    }
  }
}

@page {
  size: A4;
  margin: 0;
}

@media print {
  html, body {
    background: white;
    width: 210mm;
    height: 297mm;
  }
  .print-page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;

    .footer {
      padding-bottom: 0 !important;
    }
  }

}

@media screen and (min-width: 600px) {
  .print-page {
    zoom: 0.7;
    -moz-transform: scale(0.7);
  }
}

@media screen and (min-width: 800px) {
  .print-page {
    zoom: 0.9;
    -moz-transform: scale(0.9);
  }
}

@media screen and (min-width: 1000px) {
  .print-page {
    zoom: 1;
    -moz-transform: scale(1);
  }
}

@media screen and (min-width: 1200px) {
  .print-page {
    zoom: 1.2;
    -moz-transform: scale(1.2);
  }
}

@media screen and (max-width: 600px) {
  .print-page {
    zoom: 0.4;
    -moz-transform: scale(0.4);
  }
}
</style>
